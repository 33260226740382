jQuery( document ).ready(function() {

    jQuery(window).scroll(function(){
        if (jQuery(this).scrollTop() > 0) {
            jQuery('#full-navi').addClass('fixed');
        } else {
            jQuery('#full-navi').removeClass('fixed');
        }
    });

    jQuery(window).resize(function() {
        
        var teaserHeight = jQuery(".titleTeaser").outerHeight();
        
        jQuery('#triangleTeaser').css( "border-bottom", teaserHeight + "px solid #CC2D98");
    });
    
    jQuery(window).trigger('resize');

    // // Hide Header on on scroll down
    // var didScroll;
    // var lastScrollTop = 0;
    // var delta = 5;
    // var navbarHeight = jQuery('.full-navi').outerHeight();

    // jQuery(window).scroll(function(event){
    //     didScroll = true;
    // });

    // setInterval(function() {
    //     if (didScroll) {
    //         hasScrolled();
    //         didScroll = false;
    //     }
    // }, 250);

    // function hasScrolled() {
    //     var st = jQuery(this).scrollTop();
        
    //     // Make sure they scroll more than delta
    //     if(Math.abs(lastScrollTop - st) <= delta)
    //         return;
        
    //     // If they scrolled down and are past the navbar, add class .nav-up.
    //     // This is necessary so you never see what is "behind" the navbar.
    //     if (st > lastScrollTop && st > navbarHeight){
    //         // Scroll Down
    //         jQuery('.full-navi').removeClass('nav-down').addClass('nav-up');
    //     } else {
    //         // Scroll Up
    //         if(st + jQuery(window).height() < jQuery(document).height()) {
    //             jQuery('.full-navi').removeClass('nav-up').addClass('nav-down');
    //         }
    //     }
        
    //     lastScrollTop = st;
    // }   

    jQuery('#menuToggle').on('click', function(){
        jQuery('html').toggleClass('openMenu');
    });

    var path = window.location.href;
    jQuery('.navbar-nav li a').each(function(){
        var jQuerythis = jQuery(this);
        // if the current path is like this link, make it active
        if(this.href === path){
            jQuerythis.addClass("activePage");
        }
    })

    var url=window.location;
    var hash = url.hash.substr(1);

    if(hash == "ursache"){
        jQuery('#ursache').parent().addClass("pinkSection_priority");
        jQuery('#hoerverLust_item').addClass("activePage");
    }
    else if(hash == "arten"){
        jQuery('#arten').parent().addClass("pinkSection_priority");
        jQuery('#hoerverLust_item').addClass("activePage");
    }
    else if(hash == "horTest"){
        jQuery('#hoerverLust_item').addClass("activePage");
    }
    else if(hash == "folgen"){
        jQuery('#folgen').parent().addClass("pinkSection_priority");
        jQuery('#hoerverLust_item').addClass("activePage");
    }

    
 
});

